import {
    medisage_blog,community
  } from "../../js/path";
  import MixinRequest from "../../mixins/request";
  import _ from 'lodash';
  
  export default {
    data() {
      return {
        pageNo: null,
        status: false,
        commuinityStatus: false,
        oldForm: {},
        posts_id: null,
        table_header: [],
        fields: [{
            key: "id",
            sortable: true,
          },
          {
            key: "post_title",
            label: 'Title'
          },
          {
            key: "post_status",
            label: "Status",
          },
          {
            key: "social_media",
            label: "Social Media",
          },
          {
            key: "edit",
          },
          {
            key: "delete",
          },
        ],
        params: "",
        tableData: [],
        activeTab: "all",
        key: 0,
        currentPage: 1,
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: true,
        forum: [],
        partner: [],
        category: [],
        community: [],
        tags: [],
        expert: [],
        knowledgeCategory: [],
        videos: [],
        form: {
          post_title: "",
          post_content: "",
          post_name: "",
          meta_title: "",
          meta_description: "",
          meta_keywords: "",
          category_selected: [],
          community: [],
        },
        image_url: "",
        image: "",
        edit: {
          image_url: null,
        },
      };
    },
    methods: {
      slugify() {
        this.post_header = this.form.post_title;
        if (this.post_header) {
          const slug_generate = this.post_header
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, "")
            .replace(/--+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
          this.form.post_name = slug_generate;
        } else {
          this.form.post_name = null;
        }
      },
      searchFor() {
        if (this.filter.length > 1) this.fetchData(this.activeTab);
        else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url = medisage_blog.getMediBlogPostUrl + "?filter=" + txt + "&search=" + this.filter;
          } else {
            url = medisage_blog.getMediBlogPostUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url = medisage_blog.getMediBlogPostUrl + "?filter=" + txt + "&search=" + this.filter;
          } else {
            url = medisage_blog.getMediBlogPostUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url = medisage_blog.getMediBlogPostUrl + "?filter=" + txt + "&search=" + this.filter;
          } else {
            url = medisage_blog.getMediBlogPostUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.getUrl();
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async fetchPosts(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let url = medisage_blog.getMediBlogPostUrl + "/" + id;
          this.$store.commit("loader/updateStatus", true);
          const data = await this.getRequest(url);
          this.$store.commit("loader/updateStatus", false);
  
          if (data.status) {
            const responseData = data.response;
  
            if (responseData.post_title != "" && responseData.post_title != null) {
              this.form.post_title = responseData.post_title;
            }
  
            if (responseData.post_content != "" && responseData.post_content != null) {
              this.form.post_content = responseData.post_content;
            }

            if (responseData.post_name != "" && responseData.post_name != null) {
              this.form.post_name = responseData.post_name;
            }
  
            if (responseData.meta_title != "" && responseData.meta_title != null) {
              this.form.meta_title = responseData.meta_title;
            }
  
            if (responseData.meta_description != "" && responseData.meta_description != null) {
              this.form.meta_description = responseData.meta_description;
            }
  
            if (responseData.meta_keywords != "" && responseData.meta_keywords != null) {
              this.form.meta_keywords = responseData.meta_keywords;
            }
  
            if (responseData.post_status) {
              this.form.post_status = true;
            }

            if (
              responseData.category_selected != "" &&
              responseData.category_selected != null
           ) {
              this.form.category_selected =
                 responseData.category_selected.map((c) => {
                    return {
                       id: c.category_id,
                       name: c.name,
                    };
                 });
           }

           if (responseData.community != null && responseData.community != "") {
            this.form.community = responseData.community.map((item) => {
              return item;
            });
          }
           
            if (responseData.tags != null && responseData.tags != "") {
              this.form.tags = responseData.tags;
            }
            this.edit.image_url = responseData.image;
            console.log(this.edit.image_url);
  
            this.posts_id = responseData.id;
            this.oldForm = _.clone(this.form);
  
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async fetchAllCategory() {
        const url = medisage_blog.fetchAllActiveCategory;
        const data = await this.getRequest(url);
        if (data.status) {
           this.form.category_selected = data.response.data;
        }
     },
     async fetchAllCommunity() {
        const url = community.fetchAllActiveCommunity;
        const data = await this.getRequest(url);
        if (data.status) {
          this.form.community = data.response.data;
        }
     },
     async fetchCategory(query) {
        const url = medisage_blog.fetchAllActiveCategory;
        const data = await this.getRequest(url + "&search=" + query);
        if (data.status) {
           this.category = data.response.data;
        }
     },
     async fetchCommunity(query) {
        const url = community.fetchAllActiveCommunity;
        const data = await this.getRequest(url + '&search=' + query);
        if (data.status) {
          this.community = data.response.data;
        }
      },
      async uploadImage(file) {
        const dataAppend = new FormData();
        // formData.append("file", file);
        if(file){
          dataAppend.append("file", file);
        }

        try {
          const url = medisage_blog.uploadImagesCkeditor
          const response = await MixinRequest.methods.postRequest(url, dataAppend);
          if (!response || !response.url) {
            throw new Error("Image upload failed");
          }
          return response.url; // Return S3 image URL
        } catch (error) {
          console.error("Error uploading image:", error);
          throw error;
        }
      },
      readFile(e, txt) {
        if (txt == "image") {
          this.image = e.target.files[0];
          if (this.image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded File is More than 2MB"
            });
            this.$refs.image.reset();
            this.image_url = '';
            this.edit.image_url = '';
            this.image = '';
            return false;
          } else {
            this.image_url = URL.createObjectURL(this.image);
            this.edit.image_url = '';
          }
          return true;
        }
      },
      async submitData() {
        this.$store.commit("loader/updateStatus", true);
        try {
          let url = medisage_blog.getMediBlogPostUrl;
          if (this.$route.name == "edit-posts") {
            url = medisage_blog.getMediBlogPostUrl + "/" + this.posts_id;
          }
          let dataAppend = new FormData();
          if (this.image) {
            dataAppend.append("image", this.image);
          }
          for (var key in this.form) {
            if (key != "category_selected" && key != "community" && key != "tags") {
              dataAppend.append(key, this.form[key]);
            }
          }
          const category_selected = (this.form.category_selected || []).map(key => key.id);
          dataAppend.append("category_selected", JSON.stringify(category_selected));
          const community = (this.form.community || []).map(key => key.id);
          dataAppend.append("community", community.length > 0 ? JSON.stringify(community) : "");
          dataAppend.append("post_status", this.form.post_status ? "publish" : "unpublish");
          if (this.$route.name == "edit-posts") {
            dataAppend.append("_method", "put");
          }
      
          // Ensure tags is an array
          const tags = (this.form.tags || []).map(key => key);
          tags.forEach((t) => dataAppend.append("tags[]", t));
      
          console.log(tags);
          
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/medi-blog/posts");
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
          console.log(err);
        }
        this.$store.commit("loader/updateStatus", false);
      },      
      getUrl() {
        var url = new URL(window.location);
        if (this.activeTab == null || this.activeTab == "") {
          this.activeTab = "all";
        }
        (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
        if (this.filter != null && this.filter != "") {
          (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
        } else {
          url.searchParams.delete('search');
        }
        if (this.currentPage != null && this.currentPage != "") {
          (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
        } else {
          url.searchParams.set('page', 1);
        }
        url.search = url.searchParams;
        url = url.toString();
        history.pushState({}, null, url);
      },
      async updateStatus(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.mediBlogPostupdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            post_status: !this.tableData.data[index].post_status,
            id: id,
          });
          if (data.status) {
            this.tableData.data[index].post_status = !this.tableData.data[index].post_status;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async socialUpdateStatus(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.mediBlogPostSocialUpdateStatus + "/" + id;
          const data = await this.postRequest(url, {
            social_media: !this.tableData.data[index].social_media,
            id: id,
          });
          if (data.status) {
            this.tableData.data[index].social_media = !this.tableData.data[index].social_media;
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
          this.key += 1;
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async deletePosts(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.getMediBlogPostUrl + "/" + id;
          const data = await this.postRequest(url, {
            _method: "DELETE",
          });
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      async restorePosts(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let index = this.tableData.data.findIndex((e) => e.id === id);
          const url = medisage_blog.restoreMediBlogPost + "/" + id;
          const data = await this.postRequest(url);
          if (data.status) {
            this.tableData.data.splice(index, 1);
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      tabActive() {
        if (this.activeTab == "trash") {
          if (this.table_header.includes("delete")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            this.fields[index] = {
              key: "restore",
            };
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header[table_index] = "restore";
          } else {
            this.table_header.push("restore");
            this.fields.push({
              key: "restore",
            });
          }
  
          if (!this.can("restore-posts")) {
            let index = this.fields.findIndex((item) => item.key == "restore");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "restore"
              );
              this.table_header.splice(table_index, 1);
            }
          }
  
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (this.table_header.includes("restore")) {
            if (!this.can("edit-posts")) {
              this.fields[3] = {
                key: "delete",
              };
            } else {
              this.fields[4] = {
                key: "delete",
              };
            }
  
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header[table_index] = "delete";
          }
  
          if (!this.can("edit-posts")) {
            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "edit"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("edit")) {
              this.table_header.push("edit");
              this.fields[3] = {
                key: "edit",
              };
            }
          }
  
          if (!this.can("delete-posts")) {
            let index = this.fields.findIndex((item) => item.key == "delete");
            if (index !== -1) {
              this.fields.splice(index, 1);
              let table_index = this.table_header.findIndex(
                (item) => item == "delete"
              );
              this.table_header.splice(table_index, 1);
            }
          } else {
            if (!this.table_header.includes("delete")) {
              this.table_header.push("delete");
              this.fields[4] = {
                key: "delete",
              };
            }
          }
        }
      },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      activeTab(v) {
        if (v) this.tabActive();
      },
      "form.post_status"(v) {
        if (v == 1) {
          this.post_status = true;
        } else {
          this.post_status = false;
        }
      },
      "form.social_media"(v) {
        if (v == 1) {
          this.social_media = 1;
        } else {
          this.social_media = 0;
        }
      },
      "status"(v) {
        if (v == 1) {
          this.fetchAllCategory();
        } else {
          if (this.$route.name == 'add-posts') {
            this.form.category_selected = [];
          } else if (this.$route.name == 'edit-posts') {
            this.form.category_selected = this.oldForm.category_selected;
          }
        }
      },
      "commuinityStatus"(v) {
        if (v == 1) {
          this.fetchAllCommunity();
        } else {
          if (this.$route.name == 'add-posts') {
            this.form.community = [];
          } else if (this.$route.name == 'edit-posts') {
            this.form.community = this.oldForm.community;
          }
        }
      },
    },
    created() {
      if (this.$route.name == "add-posts" || this.$route.name == "edit-posts") {
        if (this.$route.name == "edit-posts") {
          this.fetchPosts(this.$route.params.id);
        }
      } else {
        this.activeTab = "all";
        if (this.$route.query.filter) {
          this.activeTab = this.$route.query.filter;
        }
        if (this.$route.query.search) {
          this.filter = this.$route.query.search;
        }
        if (this.$route.query.page && this.$route.query.page != 1) {
          this.currentPage = parseInt(this.$route.query.page);
        } else {
          this.fetchData(this.activeTab);
        }
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      this.tabActive();
    },
  };
<template>
    <Layout>
      <template v-if="this.$route.name == 'add-posts'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form-wizard
                @on-complete="submitData"
                color="#556ee6"
                enctype="multipart/form-data"
                ref="series"
                :finishButtonText="
                  this.$route.name == 'add-posts' ? 'Save as Draft' : 'Update Data'
                "
                back-button-text="Go Back!"
                next-button-text="Go Next!"
              >
                <tab-content
                  title="Posts Details"
                  icon="mdi mdi-account-details"
                  :before-change="() => validateFormOne()"
                >
                  <b-form-group>
                    <label for="title"
                      >Posts Title <span style="color: red">*</span></label
                    >
                    <b-form-input
                      id="title"
                      v-model="form.post_title"
                      @keyup.prevent="slugify"
                      placeholder="Enter Post Title"
                      :class="{
                        'is-invalid': submitted && $v.form.post_title.$invalid,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.form.post_title.required"
                      class="invalid-feedback"
                    >
                    Post Title is required.
                    </div>
                  </b-form-group>
                  <b-form-group>
                    <label for="post_name">Post Name </label>
                    <b-form-input id="post_name" v-model="form.post_name" placeholder="Enter URL Link"></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label for="de"
                      >Tell Us More About The Post
                      <span style="color: red">*</span></label
                    >
                    <ckeditor
                      v-model="form.post_content"
                      :editor="editor"
                      :config="editorConfig"
                      :class="{
                        'is-invalid': submitted && $v.form.post_content.$invalid,
                      }"
                    ></ckeditor>
                    <div
                      v-if="submitted && !$v.form.post_content.required"
                      class="invalid-feedback"
                    >
                      Description is required.
                    </div>
                  </b-form-group>
                  <b-form-group
                    label="Thumbnail Image [Upload Max File Size : 2MB]"
                    label-for="im"
                  >
                    <b-form-file
                      id="im"
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'image')"
                      ref="image"
                    ></b-form-file>
                    <template
                      v-if="$route.name == 'edit-posts' && edit.image_url"
                    >
                      <img
                        :src="edit.image_url"
                        width="128px"
                        height="128px"
                      />
                    </template>
                    <template v-if="image_url">
                      <img
                        :src="image_url"
                        width="128px"
                        height="128px"
                        ref="image_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <div class="row">
                    <b-form-group class="col-6">
                      <div
                        class="d-flex justify-content-between align-items-center mb-2"
                      >
                        <label for="comm" class="mb-0"
                          >Category <span style="color: red">*</span></label
                        >
                        <b-form-checkbox
                          v-model="status"
                          button
                          button-variant="info"
                          size="sm"
                        >
                          <template v-if="status">Unselect All</template>
                          <template v-else>Select All</template>
                        </b-form-checkbox>
                      </div>
                      <multiselect
                        @search-change="fetchCategory"
                        id="comm"
                        v-model="form.category_selected"
                        :options="category"
                        :multiple="true"
                        track-by="id"
                        label="name"
                        :class="{
                          'is-invalid':
                            submitted && $v.form.category_selected.$invalid,
                        }"
                      >
                        <span slot="noOptions"> Type here to search </span>
                      </multiselect>
                      <div
                        v-if="submitted && !$v.form.category_selected.required"
                        class="invalid-feedback"
                      >
                        Category is required.
                      </div>
                    </b-form-group>
                    <b-form-group class="col-6">
                      <div
                        class="d-flex justify-content-between align-items-center mb-2"
                      >
                        <label for="comm" class="mb-0"
                          >Community <span style="color: red">*</span></label
                        >
                        <b-form-checkbox
                          v-model="commuinityStatus"
                          button
                          button-variant="info"
                          size="sm"
                        >
                          <template v-if="commuinityStatus">Unselect All</template>
                          <template v-else>Select All</template>
                        </b-form-checkbox>
                      </div>
                      <multiselect
                        id="input-grp-6"
                        @search-change="fetchCommunity"
                        v-model="form.community"
                        :options="community"
                        :multiple="true"
                        track-by="id"
                        label="title"
                        placeholder="Type here to search"
                      >
                        <span slot="noOptions"> Type here to search </span>
                      </multiselect>
                      <div
                        v-if="submitted && !$v.form.community.required"
                        class="invalid-feedback"
                      >
                        Community is required.
                      </div>
                    </b-form-group>
                    <b-form-group
                      label="Enter Free tags"
                      label-for="tags-separators"
                      class="col-6"
                    >
                      <b-form-tags
                        input-id="tags-separators"
                        v-model="form.tags"
                        tag-variant="primary"
                        tag-pills
                        separator=","
                        placeholder="Enter new tags separated by comma (,) and press Enter"
                      >
                      </b-form-tags>
                    </b-form-group>
                  </div>
                </tab-content>
  
                <tab-content
                  title="Add Tags"
                  icon="fa fa-tag"
                  :before-change="() => validateFormTwo()"
                >
                  <div class="row">
                    <b-form-group
                      class="col-6 position-relative"
                      label="Meta Title"
                      label-for="title"
                    >
                      <b-form-input
                        id="title"
                        :maxlength="250"
                        v-model="form.meta_title"
                        placeholder="Enter Meta Title"
                      >
                      </b-form-input>
                      <div class="text-right">
                        <p
                          v-if="form.meta_title"
                          class="badge position-absolute"
                          style="top: 4px; right: 13px"
                          :class="{
                            'badge-success': form.meta_title.length !== 250,
                            'badge-danger': form.meta_title.length === 250,
                          }"
                        >
                          You typed
                          {{ form.meta_title.length }} out of 250 chars.
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="col-6 position-relative"
                      label="Meta Keywords"
                      label-for="key"
                    >
                      <b-form-input
                        :maxlength="250"
                        id="key"
                        v-model="form.meta_keywords"
                        placeholder="Enter Meta Keywords"
                      >
                      </b-form-input>
                      <div class="text-right">
                        <p
                          v-if="form.meta_keywords"
                          class="badge position-absolute"
                          style="top: 4px; right: 13px"
                          :class="{
                            'badge-success': form.meta_keywords.length !== 250,
                            'badge-danger': form.meta_keywords.length === 250,
                          }"
                        >
                          You typed
                          {{ form.meta_keywords.length }} out of 250 chars.
                        </p>
                      </div>
                    </b-form-group>
                  </div>
  
                  <b-form-group
                    id="input-group-20"
                    label="Meta Description"
                    label-for="input-20"
                  >
                    <b-form-textarea
                      id="input-20"
                      v-model="form.meta_description"
                      placeholder="Enter Meta Description"
                      rows="3"
                    >
                    </b-form-textarea>
                  </b-form-group>
  
                  <b-form-group>
                    <div style="display: flex; gap: 10px">
                      <b-form-checkbox v-model="form.post_status"
                        >Publish
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </tab-content>
              </form-wizard>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../../mixins/request";
  import postsMixin from "../../../../mixins/ModuleJs/medi-blog-posts";
  import { required, maxLength } from "vuelidate/lib/validators";
  import { FormWizard, TabContent } from "vue-form-wizard";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "../../../../../node_modules/@ckeditor/ckeditor5-build-classic";
  import Multiselect from "vue-multiselect";
  
  export default {
    data() {
      return {
        editor: ClassicEditor,
        editorConfig: {
          extraPlugins: [MyUploadAdapterPlugin],
          image: {
            styles: ["alignLeft", "alignCenter", "alignRight"], // Enable alignment options
            toolbar: ["imageStyle:alignLeft", "imageStyle:alignCenter", "imageStyle:alignRight"],
          },
        },
        submitted: false,
        store: false,
        title1: "Add Medisage Blog Posts",
        title2: "Edit Medisage Blog Posts",
        items: [
          {
            text: "Back",
            href: "/medi-blog/posts",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, postsMixin],
    components: {
      ckeditor: CKEditor.component,
      Layout,
      PageHeader,
      FormWizard,
      TabContent,
      Multiselect
    },
    validations: {
      form: {
        post_title: { required, maxLength: maxLength(400) },
        post_content: { required },
        category_selected: { required },
        community: { required },
      },
    },
    methods: {
      validateFormOne() {
        this.submitted = true;
        if (this.$v.form.post_title.$invalid || this.$v.form.post_content.$invalid || this.$v.form.category_selected.$invalid || this.$v.form.community.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        return true;
      },
      validateFormTwo() {
        this.store = true;
        return true;
      },
      getFormTitle(id) {
        let res = this.forum.filter((f) => f.id == id);
        if (res.length > 0) {
          return res[0].title;
        }
        return null;
      },
    },
  };
  function MyUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
  try {
    const file = await this.loader.file;
    const imageUrl = await postsMixin.methods.uploadImage(file); // Await the function
    console.log('Uploaded Image URL:', imageUrl);
    return { default: imageUrl }; // CKEditor expects an object with `default` key
  } catch (error) {
    console.error('Error uploading image:', error);
    return Promise.reject(error);
  }
}


  abort() {
    console.log('Upload aborted');
  }
}
  </script>
  